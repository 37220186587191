<script>
import { h } from 'vue';
import HTMLWrapper from '~/components/HTMLWrapper.vue';
import DynamicForm from './dynamic-form.vue';

import {
  map,
  includes,
  filter,
  isEmpty,
  split,
  pipe,
  curry,
  reduce
} from 'ramda';

const getLeadForms = (leadFormData) => {
  return reduce(
    (acc, cur) => {
      acc[cur.form_id] = cur;
      return acc;
    },
    {},
    leadFormData
  );
};

/**
 * If it is a lead form key, then it will return a
 * rendered dynamic form with all the form data props
 * read in. Otherwise it will just render the string as markup.
 */
const returnMarkupOrComponent = (
  tags,
  profileEmail,
  leadFormData,
  title,
  markup
) => {
  return includes(markup, tags) && leadFormData[markup]
    ? h(DynamicForm, {
        formData: leadFormData[markup],
        profileEmail,
        title
      })
    : h(HTMLWrapper, {
        htmlString: markup
      });
};

export default {
  props: {
    inData: Object,
    template: {
      type: Object,
      default: () => ({ bodyText: '', tags: [] })
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      templateRender: null
    };
  },
  render() {
    if (!this.templateRender) {
      return h('div', '');
    } else {
      return this.templateRender;
    }
  },
  watch: {
    template: {
      immediate: true,
      handler(newTemplate) {
        this.templateRender = pipe(
          split('##'),
          filter((s) => s && !isEmpty(s)),
          map(
            curry(returnMarkupOrComponent)(newTemplate.tags)(
              this.inData.profileEmail,
              getLeadForms(this.inData.leadFormData),
              this.title
            )
          )
        )(newTemplate.bodyText);
      }
    }
  }
};
</script>
<style scoped>
@import url('https://cdn.jsdelivr.net/npm/quill@2.0.0-rc.4/dist/quill.snow.css');
</style>
