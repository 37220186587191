import { hasPath, pathOr } from 'ramda';
import { createVCF } from '~/scripts';

export const detectBrowser = (navigator) => {
  if (
    (navigator.userAgent.indexOf('Opera') ||
      navigator.userAgent.indexOf('OPR')) != -1
  ) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    return 'Firefox';
  } else if (
    navigator.userAgent.indexOf('MSIE') != -1 ||
    !!document.documentMode == true
  ) {
    return 'IE';
  } else {
    return 'Unknown';
  }
};

export const isIos = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

const isAndroid = (navigator) => navigator.userAgent.match(/Android/i);

export const isSafariIOs = () => {
  if (
    !isStandalone(navigator) &&
    isIos(navigator) &&
    detectBrowser(navigator) === 'Safari'
  ) {
    if (
      !navigator.userAgent.match('CriOS') &&
      !navigator.userAgent.match('FxiOS')
    ) {
      return true;
    }
  }
};

const isStandalone = (navigator) =>
  'standalone' in navigator && navigator.standalone;

export const isMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export default (
  prod = true,
  profile,
  onInstall = () => {},
  onAddToContacts = () => {},
  reactive = (s) => s
) => {
  const state = reactive({
    showSaveButton: false,
    showInstallPrompt: false
  });

  let deferredPrompt = false;

  const triggerInstall = () => {
    if (deferredPrompt && !isStandalone(navigator)) {
      deferredPrompt.prompt();
      deferredPrompt = null;
    } else if (isStandalone(navigator)) {
      state.showSaveButton = false;
    } else {
      // show download popup if pwa is not available
      state.showInstallPrompt = true;
    }
    onInstall();
  };

  const handleSaveButtonPrompt = () => {
    if (window) {
      console.log('window detected');
      window.addEventListener('beforeinstallprompt', (e) => {
        console.log('detected: Chrome');
        e.preventDefault();
        deferredPrompt = e;
        state.showSaveButton = true;
      });

      window.addEventListener('appinstalled', () => {
        console.log('detected: App installed');
        deferredPrompt = null;
        state.showSaveButton = false;
      });
    }

    if (
      !isStandalone(navigator) &&
      isIos(navigator) &&
      detectBrowser(navigator) === 'Safari'
    ) {
      if (
        !navigator.userAgent.match('CriOS') &&
        !navigator.userAgent.match('FxiOS')
      ) {
        console.log('detected: Safari');
        state.showSaveButton = true;
      } else {
        console.log('detected: chrome/firefox for IOS');
      }
    } else if (
      !isStandalone(navigator) &&
      detectBrowser(navigator) === 'Firefox' &&
      isAndroid(navigator)
    ) {
      console.log('detected: Firefox');
      state.showSaveButton = true;
    }
  };

  const getPWAPicture = (useCompany = false) => {
    if (useCompany && hasPath(['company', 'icon', 'url'], profile))
      return profile.company.icon.url;
    return pathOr('', ['person', 'profile_gallery', '0', 'url'], profile);
  };

  const shouldUseCompanyLogoAsPWAIcon = () =>
    !pathOr(false, ['person', 'profile_gallery', '0', 'url'], profile);
  const vcfDownloadUrl = () => {
    onAddToContacts();
    return createVCF(profile);
  };
  return {
    get profilePicture() {
      return getPWAPicture(shouldUseCompanyLogoAsPWAIcon(profile));
    },
    triggerInstall: prod ? triggerInstall : () => {},
    handleSaveButtonPrompt: prod ? handleSaveButtonPrompt : () => {},
    handelSaveClick(saveProfile = false) {
      return state.showSaveButton ||
        (saveProfile && typeof saveProfile == 'boolean')
        ? triggerInstall(profile)
        : vcfDownloadUrl();
    },
    vcfDownloadUrl,
    isFirefox: detectBrowser(navigator) == 'Firefox',
    get showSaveButton() {
      return state.showSaveButton;
    },
    get showInstallPrompt() {
      return state.showInstallPrompt;
    },
    set showInstallPrompt(value) {
      state.showInstallPrompt = value;
    }
  };
};
