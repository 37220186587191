<template>
  <div>
    <form
      class="my-2 rounded contact-form"
      method="POST"
      @submit="submitForm"
      :action="formData.form_action"
      :target="formData.form_action ? '_blank' : ''"
      novalidate
    >
      <div class="grid gap-6 grid-cols-2">
        <div
          v-for="(field, index) in inputFields"
          v-bind:key="field.id"
          :class="[
            field.type === 'message' || field.type === 'checkbox'
              ? 'col-span-2'
              : 'col-span-2 md:col-span-1',
            'lead-form-' + field.field_name,
            { 'error-field': fieldErrors[index] }
          ]"
        >
          <div v-if="field.before" v-html="field.before"></div>
          <div
            v-show="
              field.type === 'text' ||
              field.type === 'number' ||
              field.type === 'numeric' ||
              field.type === 'email'
            "
          >
            <label :for="field.id" class="block text-sm text-black"
              >{{ field.label
              }}<span class="text-red-500">{{
                field.required ? '*' : ''
              }}</span></label
            >

            <input
              v-model="field.value"
              :type="getType(field.type)"
              :name="field.field_name"
              :placeholder="field.helper_text"
              :id="field.id"
              :maxlength="field.type === 'number' ? '20' : ''"
              minlength="0"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div class="relative" v-if="field.type === 'date'">
            <label :for="field.id" class="block text-sm text-black"
              >{{ field.label
              }}<span class="text-red-500">{{
                field.required ? '*' : ''
              }}</span></label
            >
            <input
              type="text"
              :name="field.field_name"
              :value="field.value"
              :placeholder="field.helper_text"
              :id="field.id"
              height="100px"
              readonly
              @click="showDatePicker = true"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm cursor-pointer"
            />
            <div
              class="absolute z-10 -top-20 mt-3 -ml-1 border border-gray-300 bg-white p-2 rounded-md"
              v-if="showDatePicker"
            >
              <!-- <v-date-picker
                  v-model="dateValue"

                /> -->
              <DatePicker
                v-model="dateValue"
                mode="dateTime"
                trim-weeks
                :id="field.id"
                :name="field.field_name"
                class="mt-1 block w-full sm:text-sm border-0"
              />
              <button
                class="py-2 px-4 my-2 border border-transparent border-gray-300 shadow-sm text-sm font-medium rounded-md focus:outline-none bg-white focus:ring-2 focus:ring-offset-2"
                @click="(e) => selectDate(e, field)"
              >
                Done
              </button>
            </div>
          </div>
          <div v-if="field.type === 'dropdown'">
            <label :for="field.id" class="block text-sm text-black"
              >{{ field.label
              }}<span class="text-red-500">{{
                field.required ? '*' : ''
              }}</span></label
            >

            <v-select
              :name="field.field_name"
              :placeholder="field.helper_text"
              :id="field.id"
              height="100px"
              v-model="field.value"
              class="mt-1 bg-white sm:text-sm"
              :options="getDropdownValues(field.values)"
            />
          </div>
          <div v-if="field.type === 'message'">
            <div>
              <label :for="field.id" class="block text-sm text-black">
                {{ field.label
                }}<span class="text-red-500">{{
                  field.required ? '*' : ''
                }}</span></label
              >
              <div class="mt-1">
                <textarea
                  :id="field.id"
                  :name="field.field_name"
                  v-model="field.value"
                  :placeholder="field.helper_text"
                  rows="3"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>
              </div>
            </div>
          </div>
          <div v-if="field.type === 'checkbox'">
            <div class="flex items-start">
              <div class="h-auto">
                <input
                  :id="field.id"
                  :name="field.field_name"
                  v-model="field.value"
                  type="checkbox"
                  hidden
                />
                <i
                  @click="field.value = !field.value"
                  :class="`text-xl leading-none align-top text-gray-500 cursor-pointer fa-regular ${
                    field.value === true ? 'fa-square-check' : 'fa-square'
                  }`"
                ></i>
              </div>
              <div class="text-sm flex-1">
                <label
                  @click="field.value = !field.value"
                  :for="field.field_name"
                  class="block text-sm text-black"
                  >{{ field.label
                  }}<span class="text-red-500">{{
                    field.required ? '*' : ''
                  }}</span></label
                >
                <div
                  v-if="field.helper_text"
                  class="block text-sm font-sm text-gray-500 mt-1 italic"
                >
                  {{ field.helper_text }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="field.after" v-html="field.after"></div>
        </div>
      </div>
      <div
        class="submit-container px-4 mt-2 py-3 bg-gray-50 text-right sm:px-6 flex flex-col md:flex-row justify-center md:justify-end"
      >
        <div
          v-show="!errorMessage && !formSuccess"
          class="text-sm font-sm text-black mr-4 italic mb-2 md:mb-0 text-center justify-center align-center self-center submit-helper-text"
        >
          {{ formData.submit_helper_text ? formData.submit_helper_text : '' }}
        </div>
        <div
          v-show="errorMessage && !formSuccess"
          class="text-sm font-sm text-red-500 mr-4 italic mb-2 md:mb-0 text-center justify-center align-center self-center submit-error-message"
        >
          {{ errorMessage }}
        </div>
        <div
          v-show="formSuccess"
          class="text-sm font-sm text-green-500 mr-4 italic mb-2 md:mb-0 text-center justify-center align-center self-center submit-success-message"
        >
          {{ formData.form_submitted_message }}
        </div>
        <button
          type="submit"
          :disabled="loading"
          class="inline-flex justify-center py-2 px-4 border border-transparent border-gray-800 text-gray-800 w-full shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 submit-button"
        >
          {{ loading ? 'loading...' : formData.button_text }}
        </button>
      </div>
      <div
        class="my-4 text-sm mx-2 md:mx-4 text-gray-400 text-center hidden contact-form-privacy-policy"
      >
        By submitting this form you agree that you have read and understand our
        <a
          class="font-bold text-gray-800"
          href="https://profileme.app/privacy-policy/"
          target="__blank"
          >Privacy Policy <i class="fas fa-external-link-alt top-corner"></i
        ></a>
      </div>
    </form>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { DatePicker } from 'v-calendar';
import { find, curry, pathOr, forEachObjIndexed, isEmpty } from 'ramda';
import 'vue-select/dist/vue-select.css';
import 'v-calendar/dist/style.css';
import { $vfm } from 'vue-final-modal';

export default {
  inject: ['submitFormToDB', 'captureEvent'],
  components: {
    vSelect,
    DatePicker
  },
  props: {
    formData: Object,
    profileEmail: String,
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      formSuccess: false,
      dateValue: '',
      showDatePicker: false,
      inputFields: [],
      fieldErrors: {},
      emailValidation: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
      numberValidation: /^[0-9]+$/,
      fieldMatchesKey: curry((key, field) => field.field_name == key)
    };
  },
  created() {
    this.setFields(this.formData.fields);
  },
  methods: {
    getType(type) {
      if (type == 'number') return 'tel';
      if (type == 'numeric') return 'number';
      return type;
    },
    setFields(formDataFields) {
      this.inputFields = [];
      formDataFields?.forEach((item) => {
        this.inputFields.push({
          ...item,
          value: this.getValue(item)
        });
      });
    },
    getValue(item) {
      if (item.field_name == 'subject') return this.formData.subject;
      return item.value ? item.value : '';
    },
    selectDate(e, field) {
      e.preventDefault();
      this.showDatePicker = false;
      const d = new Date(this.dateValue);
      field.value =
        [d.getFullYear(), d.getMonth() + 1, d.getDate()].join('/') +
        ' ' +
        [d.getHours(), d.getMinutes()].join(':');
    },
    submitForm(e) {
      if (this.formData.form_action) return;
      e.preventDefault();

      this.fieldErrors = {};
      forEachObjIndexed(this.setValidationErrors, this.inputFields);
      forEachObjIndexed(this.setRequireErrors, this.inputFields);

      if (isEmpty(this.fieldErrors)) {
        this.loading = true;
        this.handleSubmitForm();
      } else {
        this.errorMessage =
          'Some fields may be missing or invalid, please review';
      }
    },
    handleSubmitForm() {
      this.fieldErrors = {};
      this.errorMessage = '';
      const onSuccess = () => {
        this.loading = false;
        this.errorMessage = '';
        this.formSuccess = true;
        this.setFields(this.formData.fields);
        this.captureEvent({
          value: '',
          type: 'lead-form',
          action: 'submit',
          title: this.title
        });
        setTimeout(() => {
          this.formSuccess = false;
          $vfm.hideAll();
        }, 5000);
      };

      const onError = (err) => {
        this.errorMessage = err.message;
        this.formSuccess = false;
      };

      const onFinally = () => {
        this.fieldErrors = {};
        this.loading = false;
      };

      this.submitFormToDB(
        { onSuccess, onError, onFinally },
        {
          profileEmail: this.profileEmail,
          emailInfo: this.getEmailInfo(this.formData, this.inputFields),
          inputFields: this.inputFields,
          formId: this.formData.form_id
        }
      );
    },
    setValidationErrors(field, index) {
      if (
        field.type == 'email' &&
        !isEmpty(field.value) &&
        !this.emailValidation.test(field.value)
      ) {
        this.fieldErrors[index] = true;
      }
    },
    setRequireErrors(field, index) {
      if (field.required && isEmpty(field.value)) {
        this.fieldErrors[index] = true;
      }
    },
    getDropdownValues(dropdown_values) {
      if (dropdown_values) return dropdown_values.split('\n');
      return [];
    },
    getFieldByName(fieldValues, key, defaultValue = '') {
      const field = find(this.fieldMatchesKey(key))(fieldValues);
      return pathOr(defaultValue, ['value'], field);
    },
    getEmailInfo(formData, fieldValues) {
      return {
        from: this.getFieldByName(fieldValues, 'email'),
        name: this.getFieldByName(fieldValues, 'full_name'),
        subject: this.getFieldByName(fieldValues, 'subject', formData.subject),
        title: formData.title ? formData.title : '',
        table_heading: formData.table_heading ? formData.table_heading : ''
      };
    }
  }
};
</script>

<style scoped>
.top-corner {
  font-size: 8px;
  position: relative;
  right: 10px;
  bottom: 5px;
}
</style>
