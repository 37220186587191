<template>
  <span v-html="htmlString"></span>
</template>

<script>
export default {
  name: 'HTMLWrapper',
  props: {
    htmlString: {
      type: String,
      required: true
    }
  }
};
</script>
