<script setup>
import titleCard from '@/components/title-card/index.vue';
import dynamic from '@/components/dynamic-component.vue';
import saveAndShare from '@/components/save-and-share.vue';
import socialMediaButtons from '@/components/social-media-buttons.vue';
import responsiveImage from '@/components/responsive-image.vue';
import bodyContentDropdown from '@/components/body-content/body-content-dropdown.vue';
import contactButtons from '@/components/contact-buttons/index.vue';
import settingsButton from '@/components/settings-button.vue';
import footerSection from '@/components/footer-section.vue';
import headerSection from '@/components/header-section.vue';
import mainSection from '@/components/main-section.vue';
import imageContainer from '@/components/image-container.vue';
import separator from '@/components/separator.vue';
import banner from '@/components/banner.vue';
import { inject } from 'vue';
defineProps({
  profileDetails: { type: Object, default: () => {} },
  saveAndShareHandler: { type: Object, default: () => {} },
  bodyContentHandler: { type: Object, default: () => {} },
  titleInfoHandler: { type: Object, default: () => {} },
  contactButtonsHandler: { type: Object, default: () => {} },
  profileImageHandler: { type: Object, default: () => {} },
  footerHandler: { type: Object, default: () => {} },
  leadFormHandler: { type: Object, default: () => {} }
});
// Open Modal
const openSettings = inject('openSettings');
</script>
<template>
  <div
    class="desktop-template desktop-template-2 body flex flex-col items-center"
  >
    <header-section class="w-full px-10">
      <template #left>
        <responsive-image
          v-if="profileImageHandler.logo.url"
          class="h-full"
          :image="profileImageHandler.logo.url"
          :width="profileImageHandler.logo.width"
          :alt="profileImageHandler.logo.alt"
          :widthMobile="'50%'"
          :widthDesktop="'307px'"
        />
      </template>
      <template #right>
        <save-and-share
          :showSaveButton="saveAndShareHandler.showSaveButton"
          @saveClick="saveAndShareHandler.handelSaveClick()"
        />

        <social-media-buttons
          class="desktop hidden lg:flex"
          background="bg-brand"
          :contacts="profileDetails.contacts"
        />
      </template>
    </header-section>
    <image-container
      class="banner w-full"
      containerClass="w-full flex justify-center"
      :bannerBackground="profileImageHandler.desktopImage"
    >
      <div class="main-width w-full">
        <banner
          v-if="profileImageHandler.mobileBanner.length"
          class="w-full mobile-banner z-10 lg:w-2/5"
          :banners="profileImageHandler.mobileBanner"
        />

        <dynamic
          class="w-full mobile-banner z-10 lg:w-2/5"
          v-else
          :template="profileImageHandler.videoEmbed"
        ></dynamic>
      </div>
    </image-container>

    <main class="main-width flex w-full lg:mb-20">
      <main-section
        class="contact details flex flex-col max-w-full w-full z-10 lg:w-2/5"
        :bannerBackground="profileImageHandler.bannerBackground"
        containerClass="w-full px-5 pb-6"
        :desktopCenterY="false"
        style="height: min-content"
        :class="{
          'pt-8': !profileImageHandler.mobileBanner.length
        }"
      >
        <div>
          <title-card
            class="mb-10"
            :class="{
              '-mt-20': profileImageHandler.mobileBanner.length
            }"
            :titleInfoHandler="titleInfoHandler"
            :saveAndShareHandler="saveAndShareHandler"
          />

          <contact-buttons
            :showValue="true"
            :contactButtonsHandler="contactButtonsHandler"
            data-nosnippet="false"
            @saveContact="saveAndShareHandler.vcfDownloadUrl"
          />
        </div>
      </main-section>
      <div
        class="content details flex-1 ml-20 mt-10 hidden flex-col items-center lg:flex lg:w-3/5"
      >
        <body-content-dropdown
          class="w-full"
          :bodyContentHandler="bodyContentHandler"
          :exclusiveOpen="true"
          :leadFormHandler="leadFormHandler"
        />

        <div class="logo top flex justify-center mt-10 -mb-1">
          <responsive-image
            class="w-2/4"
            v-if="profileImageHandler.logo.url"
            :image="profileImageHandler.logo.url"
            :width="profileImageHandler.logo.width"
            :alt="profileImageHandler.logo.alt"
            :widthMobile="'50vw'"
            :widthDesktop="'307px'"
          />
        </div>

        <social-media-buttons
          class="main-footer-social-media mt-10 mobile text-white"
          background="bg-brand"
          :contacts="profileDetails.contacts"
        />
      </div>
    </main>
    <body-content-dropdown
      class="my-8 w-full lg:hidden px-5 lg:px-10"
      :bodyContentHandler="bodyContentHandler"
      :exclusiveOpen="true"
      :leadFormHandler="leadFormHandler"
    />

    <save-and-share
      class="px-5 lg:hidden"
      :showSaveButton="saveAndShareHandler.showSaveButton"
      :buttonLayout="true"
      @saveClick="saveAndShareHandler.handelSaveClick()"
    />
    <div class="logo bottom flex justify-center lg:hidden">
      <responsive-image
        class="h-full"
        v-if="profileImageHandler.logo.url"
        :image="profileImageHandler.logo.url"
        :width="profileImageHandler.logo.width"
        :alt="profileImageHandler.logo.alt"
        :widthMobile="'50vw'"
        :widthDesktop="'307px'"
      />
    </div>
    <div class="py-9 w-full flex justify-between items-center lg:hidden">
      <!-- social-media -->
      <div class="w-12"></div>
      <social-media-buttons
        class="desktop-footer-social-media mobile"
        background="bg-brand"
        :contacts="profileDetails.contacts"
      />

      <settings-button class="grey shadow" @click="openSettings" />
    </div>
    <footer class="footer-container desktop-footer w-full px-4 bg-brand">
      <div
        class="desktop-footer-sharing border-b hidden items-center justify-between w-full h-16 lg:flex"
      >
        <div class="w-12"></div>
        <div class="flex items-center">
          <h4 class="mr-6">Follow & Connect:</h4>
          <social-media-buttons
            class="desktop-footer-social-media desktop text-white mr-2"
            :contacts="profileDetails.contacts"
          />
          <separator />
          <save-and-share
            :showSaveButton="saveAndShareHandler.showSaveButton"
            @saveClick="saveAndShareHandler.handelSaveClick()"
          >
            <template #separator>
              <separator />
            </template>
          </save-and-share>
        </div>
        <settings-button class="text-white" @click="openSettings" />
      </div>
      <footer-section class="text-white" :footerHandler="footerHandler" />
    </footer>
  </div>
</template>
<style>
@import url('./DesktopStyling.css');
:root {
  --font-color: #1b1c4b;
}
.desktop-template-2 .main-width {
  max-width: 1120px;
}
.desktop-template-2 .details-contacts-section {
  font-size: 11px;
}
.desktop-template-2 .bio-collapse-head-title {
  font-size: 13px;
}
.desktop-template-2 .profile-title-subtitle {
  padding-top: 6px;
}
@media only screen and (min-width: 1024px) {
  .desktop-template-2 .details-contacts-section {
    font-size: 12px;
  }

  .desktop-template-2 .bio-collapse-head-title {
    font-size: 16px;
  }
  .desktop-template-2 .title-image {
    width: 160px;
    height: 160px;
  }
}
</style>
