import Cookies from 'js-cookie';
import { defaultTo, hasPath, includes, pathOr, replace } from 'ramda';
import { chain } from 'lodash';

const kebabCase = (string) =>
  (string || '')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

const uniqid = (prefix = '', suffix = '') =>
  prefix + (Math.random().toString(16) + '000000000').substring(2, 16) + suffix;

const getContactDetail = (type, key, details) => {
  let result;

  result = details.find(
    (detail) => detail.contact_type == type && getKey(detail) == key
  );

  return result && result.value ? result.value : '';
};

const getKey = (obj) => {
  return obj.key ? obj.key.toLowerCase() : '';
};

const getClass = (obj) => {
  return obj.class ? obj.class.toLowerCase() : '';
};

const deepIf = (obj, keys) => {
  let result = true;

  for (let i = 0; i < keys.length; i++) {
    result = result && obj[keys[i]] !== undefined && obj[keys[i]] !== null;

    if (result) {
      if (obj[keys[i]].length !== undefined) {
        result = result && obj[keys[i]].length > 0;
      }
      obj = obj[keys[i]];
    } else {
      break;
    }
  }
  return result;
};

const deepValue = (obj, keys) => {
  if (!deepIf(obj, keys)) return '';
  let returnValue = obj;
  keys.forEach((key) => {
    returnValue = returnValue[key];
  });
  return returnValue;
};

const kebabize = (str) => {
  return str
    .split('')
    .map((letter, idx) => {
      if (letter.toUpperCase() == letter && letter != '-') {
        return `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`;
      }
      return letter.toLowerCase();
    })
    .join('');
};

const formatForSubdomain = (name) => {
  return name.split(' ').join('').toLowerCase();
};

const profileMixin = {
  inject: ['submitFormToDB', 'captureEvent'],
  props: {
    details: Object,
    imagePrefix: { type: String, default: '' },
    leadFormData: { type: Array, default: () => [] },
    privacyUrl: {
      type: String
    },
    supportUrl: {
      type: String
    },
    globalPresets: {
      type: Object,
      default: () => []
    },
    pwaHandler: {
      default: () => {
        return {
          triggerInstall: undefined,
          handelSaveClick: undefined,
          showSaveButton: false
        };
      }
    }
  },
  methods: {
    getTW(key) {
      const styling = this.details.profile_styling;
      let returnClass = key;

      if (styling && styling.styleEntries && styling.styleEntries.length) {
        const entry = styling.styleEntries.find((e) => {
          return kebabize(e.styleClass) == key;
        });
        if (entry) {
          returnClass +=
            ' ' +
            this.formatTailwind(
              entry.styling,
              styling.stylePresets,
              this.globalPresets
            );
        }
      }

      return returnClass;
    },
    formatTailwind(value, presets, globalPresets) {
      (presets || []).forEach((preset) => {
        if (value) value = value.split(`@${preset.name}@`).join(preset.styling);
      });

      (globalPresets || []).forEach((preset) => {
        if (value)
          if (value)
            value = value.split(`$${preset.name}$`).join(preset.styling);
      });

      return value;
    },
    deepIf(keys) {
      return deepIf(this.details, keys);
    },
    getSettingsConfig() {
      return this.details?.company?.settingsConfig || undefined;
    },
    getKey: getKey,
    getContactDetail
  },
  computed: {
    getNumber() {
      if (this.details.contacts) {
        const temp = getContactDetail('phone', 'mobile', this.details.contacts);
        return temp.length
          ? temp
          : getContactDetail('phone', 'office', this.details.contacts);
      }
      return '';
    },
    getEmail() {
      if (this.details.contacts) {
        return getContactDetail('email', 'email', this.details.contacts);
      }
      return '';
    },
    getName() {
      if (this.details.person) {
        return (
          (this.details.person?.firstName || '') +
          ' ' +
          (this.details.person?.lastName || '')
        );
      }
      return '';
    }
  }
};

const findLocationFromString = (value) => {
  if (
    value.includes('url') &&
    (value.includes('https://maps.google.com/') ||
      value.includes('https://maps.app.goo.gl/') ||
      value.includes('https://goo.gl/maps/')) &&
    value.includes('title')
  )
    try {
      return JSON.parse(value).url;
    } catch {
      return value;
    }
  return value;
};

const formatLink = (value, type) => {
  if (type && value) {
    switch (type.toLowerCase()) {
      case 'email':
        return `mailto:${value}`;
      case 'phone':
      case 'number':
      case 'office':
      case 'mobile':
        return `tel:${value}`;
      case 'modal':
        return '';
      default:
        return findLocationFromString(value);
    }
  }
  return '';
};

const sizedImage = (prefix, path, width) => {
  if (includes('res.cloudinary.com', path) && width) {
    return path
      .replace(/\/w_(.*)\//g, '/')
      .replace('upload', 'upload/w_' + width);
  }
  return prefix + path;
};

const getPictureForBranding = (details, useProfilePicture = true) => {
  const profilePicturePath = ['person', 'profile_gallery', '0', 'url'];
  const companyIconPath = ['company', 'icon', 'url'];
  if (
    defaultTo(true, useProfilePicture) &&
    hasPath(profilePicturePath, details)
  )
    return pathOr('', profilePicturePath, details);
  return pathOr('', companyIconPath, details);
};

const createVCF = async (data) => {
  let fileName = '';
  let firstName = '';
  let lastName = '';
  let birthday = '';
  let organization = '';
  let url = document?.URL;
  let photo = '';

  const title = data?.biography ? data.biography.job_title : '';

  const workPhone = getContactDetail('phone', 'office', data.contacts);

  const cellPhone = getContactDetail('phone', 'mobile', data.contacts);

  const workEmail = getContactDetail('email', 'email', data.contacts);

  firstName = deepValue(data, ['person', 'first_name']);
  lastName = deepValue(data, ['person', 'last_name']);
  fileName = firstName + ' ' + lastName;
  birthday = new Date(deepValue(data, ['person', 'date_of_birth']));

  if (data.company) {
    organization = data.company.title ? data.company.title : data.company.name;
  }

  const getBase64FromUrl = async (urlString) => {
    const image = await fetch(urlString);
    const blob = await image.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const profilePicture = sizedImage(
    '',
    getPictureForBranding(data, data.useProfilePictureAsVCFIcon),
    400
  );

  //link to image
  if (profilePicture.length) {
    const temp = profilePicture.split('.');

    await getBase64FromUrl(profilePicture).then((encoded) => {
      let encodedString = encoded
        .replace('data:image/jpeg;base64,', '')
        .replace('data:image/png;base64,', '')
        .replace('data:image/svg+xml;base64,', '');

      photo = `ENCODING=b;TYPE=${temp[temp.length - 1]}:${encodedString}`;
    });
  }

  const calculateBirthday = () => {
    const year = new Date(birthday).getFullYear();
    let month = new Date(birthday).getMonth() + 1;
    let day = new Date(birthday).getDate();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    return `${year}${month}${day}`;
  };

  // construct vcard
  const cardDetails = `
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${firstName} ${lastName}
N;CHARSET=UTF-8:${lastName};${firstName};;;
BDAY:${calculateBirthday()}
EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:${workEmail}
PHOTO;${photo}
TEL;TYPE=CELL:${cellPhone}
TEL;TYPE=WORK,VOICE:${workPhone}
TITLE;CHARSET=UTF-8:${title}
ORG;CHARSET=UTF-8:${organization}
URL;CHARSET=UTF-8:${url}
REV:${new Date().toISOString()}
END:VCARD
`;

  let element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/vcard;charset=utf-8,' + encodeURIComponent(cardDetails)
  );
  element.setAttribute('download', fileName + '.vcf');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  return true;
};

const getTitle = (details, slug) => {
  let title = '';
  if (deepIf(details, ['person', 'first_name'])) {
    title = `${details.person.first_name}`;
  }

  if (deepIf(details, ['person', 'last_name'])) {
    title += ` ${details.person.last_name}`;
  }

  if (!title.length && details.biography) {
    title = `${slug} - ${details.biography.job_title}`;
  }

  return title;
};

const cookieExists = (cookieName) => {
  return Cookies.get(cookieName) ? true : false;
};
const createCookie = (cookieName, value, time) => {
  Cookies.set(cookieName, value, time, window.location.pathname);
};
const getCookieIfExistsElseMakeCookie = (cookieName) => {
  if (!cookieExists(cookieName))
    Cookies.set(
      '_uviewer',
      uniqid('pm-', `-${new Date().getTime()}`),
      -1,
      window.location.pathname
    );

  return Cookies.get(cookieName);
};

const getOrCreateElement = (id) => {
  if (document.getElementById(id)) return document.getElementById(id);
  const elem = document.createElement('style');
  elem.id = id;
  return elem;
};

const imageError = (event) => {
  const findError = (element, errorClass = 'errorBreak') => {
    if (element) {
      // Base case: If the element has the class 'errorBreak', return it
      if (element.classList.contains(errorClass)) return element;
      // Recursive case: If the element doesn't have the class 'errorBreak', parse its parent
      if (element.parentElement) return findError(element.parentElement);
      // If the element has no parent and no 'errorBreak' class, return null
    }
    return null;
  };

  findError(event.target)?.setAttribute(
    'image-error-attribute',
    'hide-this-image'
  );
};

const getReferer = (defaultReferer) => {
  const referer = document.getElementById('referer');
  const refererValue = referer?.attributes?.content?.value;
  if (!refererValue || refererValue == 'null' || refererValue == 'undefined') {
    if (defaultReferer) return defaultReferer;
    return 'none';
  }
  return referer.innerHTML;
};

const normalYoutubeUrl = 'https://youtu.be/';
const normalYoutubeUrlAlt = 'https://www.youtube.com/watch?v=';
const embedYoutubeUrl = 'https://www.youtube.com/embed/';
const youtubeUrlPreviewImage =
  'https://img.youtube.com/vi/{{IMAGE_ID}}/hqdefault.jpg';

const convertEmbedToURL = (video) =>
  chain(video)
    .split('src="')
    .last()
    .split('"')
    .first()
    .replace(embedYoutubeUrl, normalYoutubeUrl)
    .value();

const convertEmbedVideoToImage = (video) =>
  replace(
    '{{IMAGE_ID}}',
    replace(
      normalYoutubeUrl,
      ''
    )(replace(normalYoutubeUrlAlt, '')(replace(embedYoutubeUrl, '')(video)))
  )(youtubeUrlPreviewImage);
const resizeImage680 = (url) => {
  return replace('upload/', 'upload/w_680/', url);
};
export {
  resizeImage680,
  convertEmbedToURL,
  getReferer,
  imageError,
  convertEmbedVideoToImage,
  getContactDetail,
  deepIf,
  deepValue,
  kebabize,
  profileMixin,
  formatLink,
  sizedImage,
  getPictureForBranding,
  createVCF,
  formatForSubdomain,
  getTitle,
  getCookieIfExistsElseMakeCookie,
  cookieExists,
  createCookie,
  getOrCreateElement,
  getKey,
  getClass,
  uniqid,
  findLocationFromString,
  kebabCase
};
