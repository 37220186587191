import { filter, pathOr } from 'ramda';

export default (profile) => {
  return {
    get contacts() {
      return filter(
        (contact) =>
          contact &&
          contact.contact_type != 'socialmedia' &&
          contact.key != 'whatsapp' &&
          (contact.value || contact.contact_type == 'add-to-contacts'),
        profile.contacts
      );
    },
    get socialMedia() {
      return filter(
        (contact) => contact.contact_type == 'socialmedia',
        profile.contacts
      );
    },
    get callToAction() {
      const getCallToAction = (p, d) =>
        pathOr(d, [p], profile.call_to_action || {}) || d;
      return {
        icon: getCallToAction('icon', 'fa-solid fal fa-envelope'),
        link: getCallToAction('link', 'lead-form'),
        analyticsType:
          getCallToAction('link', 'lead-form') == 'lead-form'
            ? 'lead-form'
            : 'call-to-action',
        type:
          getCallToAction('link', 'lead-form') == 'lead-form' ? 'modal' : 'url',
        content: getCallToAction(
          'content',
          'Get in Touch / Request a Call Back'
        ),
        key: getCallToAction('key', '')
      };
    }
  };
};
